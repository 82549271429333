var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unlockListWrap" },
    [
      _c("div", { staticClass: "unlockList" }, [
        _c("div", { staticClass: "unlockList__title hidden-mobile-down" }, [
          _vm.isUnlockList
            ? _c("img", {
                attrs: { src: require("@/assets/運勢書共用/購買星座title.svg") }
              })
            : _c("div", [_vm._v("我的解答之書")])
        ]),
        _vm._m(0),
        _c("div", { staticClass: "unlockList__toBuy" }, [
          _c(
            "div",
            {
              staticClass: "unlockList__toBuy__btn",
              on: {
                click: function($event) {
                  return _vm.openLockList("gift")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/占星小鋪/gift.svg"), alt: "" }
              }),
              _vm._v("\n        送禮選購\n      ")
            ]
          ),
          _c(
            "div",
            {
              staticClass: "unlockList__toBuy__all",
              on: {
                click: function($event) {
                  return _vm.openLockList("personal")
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/占星小鋪/user.svg"), alt: "" }
              }),
              _vm._v("\n        自用選購\n      ")
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "unlockList__list" },
          _vm._l(_vm.book2024_list, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "unlockList__list__item",
                on: {
                  click: function($event) {
                    return _vm.toBuyOrRead(item)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "unlockList__list__item__pic",
                    class: "background-" + ((index % 3) + 1)
                  },
                  [_c("img", { attrs: { src: item.bookImg, alt: "" } })]
                ),
                _c("div", { staticClass: "unlockList__list__item__title" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.isUnlock,
                        expression: "!item.isUnlock"
                      }
                    ],
                    attrs: {
                      src: require("../../assets/運勢書共用/icon-locked.svg")
                    }
                  }),
                  _vm._v("\n          " + _vm._s(item.cn) + "座\n        ")
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm.showBookSelectBuy
        ? _c("BookSelectBuy", {
            attrs: { productList: _vm.book2024_list },
            on: {
              exit: function($event) {
                _vm.showBookSelectBuy = false
              },
              selectProduct: _vm.toBuyWithUseType,
              buyAll: _vm.toBuyAll
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "unlockList__subtitle" }, [
      _vm._v("\n      點擊購買按鈕，"),
      _c("br", { staticClass: "hidden-mobile-up" }),
      _vm._v("探索想了解的星座~\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }