<template>
  <FixedPopupVue>
    <div class="bookSelectBuy">
      <div class="bookSelectBuy__title">選擇想購買/送禮的星座</div>
      <div class="bookSelectBuy__list">
        <!-- 全部購買 -->
        <div class="bookSelectBuy__list__item">
          <div class="bookSelectBuy__list__item__pic">
            <img src="@/assets/運勢書共用/2024全部購買符號.svg" alt="" />
          </div>
          <div class="bookSelectBuy__list__item__title">全部購買</div>
          <div class="bookSelectBuy__list__item__select" @click="toBuyAll()">
            <img src="../../assets/月亮日記/icon-ok.svg" alt="" />
          </div>
        </div>
        <!-- 星座清單 -->
        <div
          class="bookSelectBuy__list__item"
          v-for="product in productListShow"
          :key="product.ProductID"
        >
          <div class="bookSelectBuy__list__item__pic">
            <img v-if="product.img" :src="product.bookImg" alt="" />
          </div>
          <div class="bookSelectBuy__list__item__title">{{ product.cn }}座</div>
          <div
            class="bookSelectBuy__list__item__select"
            @click="selectHandler(product)"
          >
            <img src="../../assets/月亮日記/icon-ok.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="bookSelectBuy__closeBtn">
        <img
          src="../../assets/月亮日記/close.svg"
          alt=""
          @click="$emit('exit')"
        />
      </div>
    </div>
  </FixedPopupVue>
</template>

<script>
import FixedPopupVue from "../Fixed_popup.vue";
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";
export default {
  mixins: [BuyBook2024Mixin],
  components: {
    FixedPopupVue,
  },
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    productListShow() {
      let astroMap = new Map(this.$Astro.map((astro) => [astro.code, astro]));

      return this.productList.map((product) => {
        const match = astroMap.get(product.Code);
        return match ? { ...product, ...match } : product;
      });
    },
  },
  methods: {
    toBuyAll() {
      this.$emit("buyAll");
    },
    selectHandler(product) {
      this.$emit("selectProduct", product);
      this.$emit("exit");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.bookSelectBuy {
  margin: 40px auto;
  padding: 36px 12px;
  width: 520px;
  height: calc(100vh - 80px);
  max-height: 780px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background: #214c73;
  box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.3), -4px -4px 10px 0px #fff,
    -4px -4px 16px 0px #fff;
  position: relative;
  @include mobile {
    padding: 32px 8px;
    width: 326px;
    max-width: calc(100vw - 48px);
  }
  &__title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 1.92px;
    margin-bottom: 32px;
    @include mobile {
      font-size: 20px;
    }
  }
  &__list {
    width: 100%;
    height: calc(100vh - 80px - 196px);
    height: 584px;
    overflow: auto;
    @include scroll-primary;
    padding: 0 33px;
    @include mobile {
      margin-bottom: 16px;
      padding: 0 24px;
    }
    &__item {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      &__pic {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        background-color: rgba(#0c2f58, 0.5);
        border: 1px solid #a494d5;
        padding: 8px;
        @include mobile {
          width: 56px;
          height: 56px;
          padding: 4px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__title {
        margin-left: 20px;
        color: #fff;
        font-size: 20px;
        @include mobile {
          margin-left: 16px;
          font-size: 16px;
        }
      }
      &__select {
        margin-left: auto;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: rgba(#0c2f58, 0.5);
        border: 1px solid #a494d5;
        cursor: pointer;
        padding: 4px;
        @include mobile {
          width: 24px;
          height: 24px;
          padding: 3px;
        }
        img {
          width: 24px;
          height: 24px;
          @include mobile {
            width: 18px;
            height: 18px;
          }
        }
        &.active {
          background-color: #798da5;
        }
      }
    }
  }
  &__closeBtn {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
    @include mobile {
      top: 16px;
      right: 12px;
    }
    img {
      width: 40px;
      height: 40px;
      @include mobile {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
