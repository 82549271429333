<template>
  <div class="unlockListWrap">
    <div class="unlockList">
      <div class="unlockList__title hidden-mobile-down">
        <img src="@/assets/運勢書共用/購買星座title.svg" v-if="isUnlockList" />
        <div v-else>我的解答之書</div>
      </div>
      <div class="unlockList__subtitle">
        點擊購買按鈕，<br class="hidden-mobile-up" />探索想了解的星座~
      </div>
      <div class="unlockList__toBuy">
        <div class="unlockList__toBuy__btn" @click="openLockList('gift')">
          <img src="@/assets/占星小鋪/gift.svg" alt="" />
          送禮選購
        </div>
        <div class="unlockList__toBuy__all" @click="openLockList('personal')">
          <img src="@/assets/占星小鋪/user.svg" alt="" />
          自用選購
        </div>
        <!-- <div class="unlockList__toBuy__btn" @click="toBuyAll('gift')">
          全部購買 (送禮)
        </div>
        <div class="unlockList__toBuy__all" @click="toBuyAll('personal')">
          全部購買 (自用)
        </div> -->
        <!-- <div
          class="unlockList__toBuy__all"
          :class="{ disabled: allIsUnlock }"
          @click="toBuyAll"
        >
          全部購買
        </div> -->
      </div>
      <!-- <div class="unlockList__toBuy">
        <div
          class="unlockList__toBuy__btn"
          v-if="!_buyAllObj.isUnlock"
          @click="toBuyAll()"
        >
          全部解鎖
        </div>
      </div> -->
      <div class="unlockList__list">
        <div
          class="unlockList__list__item"
          v-for="(item, index) in book2024_list"
          :key="index"
          @click="toBuyOrRead(item)"
        >
          <div
            class="unlockList__list__item__pic"
            :class="`background-${(index % 3) + 1}`"
          >
            <img :src="item.bookImg" alt="" />
          </div>
          <div class="unlockList__list__item__title">
            <img
              src="../../assets/運勢書共用/icon-locked.svg"
              v-show="!item.isUnlock"
            />
            {{ item.cn }}座
          </div>
        </div>
      </div>
    </div>
    <BookSelectBuy
      v-if="showBookSelectBuy"
      :productList="book2024_list"
      @exit="showBookSelectBuy = false"
      @selectProduct="toBuyWithUseType"
      @buyAll="toBuyAll"
    ></BookSelectBuy>
  </div>
</template>

<script>
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";
import BookSelectBuy from "@/components/運勢書/BookSelectBuy";
export default {
  mixins: [BuyBook2024Mixin],
  components: {
    BookSelectBuy,
  },
  data() {
    return {
      showBookSelectBuy: false,
      bookSelectBuyList: [],
      useType: "personal",
    };
  },
  computed: {
    book2024_list() {
      return this.$store.getters.get_book2024_list.filter((item) => {
        return item.Code !== "All";
      });
    },
    isUnlockList() {
      return this.$route.name === "Book2024UnlockList";
    },
    _buyAllObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.Code === "All"
      );
    },
    allIsUnlock() {
      return this.book2024_list.every((item) => item.isUnlock);
    },
  },
  created() {
    if (this._buyAllObj && this._buyAllObj.isUnlock) {
      return this.$router.replace({ name: "Book2024My" });
    }
  },
  methods: {
    // toBuyAll() {
    //   this.mixin_buyAll();
    // },
    toBuyAll() {
      this.$router.push({
        name: "Book2024UnlockSingle",
        params: { id: this._buyAllObj.ProductID },
        query: {
          disabled: this._buyAllObj.isUnlock,
          useType: this.useType,
        },
      });
    },
    toBuyOrRead(item) {
      if (item.isUnlock) {
        this.$router.push({
          name: "Book2024ContentTrend",
          params: { id: item.en },
        });
      } else {
        this.$router.push({
          name: "Book2024UnlockSingle",
          params: { id: item.en },
        });
      }
    },
    toBuyWithUseType(item) {
      this.$router.push({
        name: "Book2024UnlockSingle",
        params: { id: item.en },
        query: {
          disabled: item.isUnlock,
          useType: this.useType,
        },
      });
    },
    openLockList(type) {
      this.useType = type;
      this.showBookSelectBuy = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

// unlockListWrap
.unlockListWrap {
  margin-top: -98px;
  padding-top: 98px;
  margin-bottom: -80px;
  padding-bottom: 80px;
  background: url("../../assets/運勢書共用/購買星座_web.png");
  min-height: 117.44vw;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  @include mobile {
    margin-top: -60px;
    padding-top: 60px;
    margin-bottom: -55px;
    padding-bottom: 55px;
    min-height: 271.2vw;
    background: url("../../assets/運勢書共用/購買星座_mobile.png");
  }
}

// main
.unlockList {
  max-width: 920px;
  margin: 120px auto;
  @include mobile {
    padding: 0 20px;
    margin: 0 auto;
  }
  &__title {
    text-align: center;
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38.119px; /* 119.123% */
    letter-spacing: 2.56px;
    margin-bottom: 80px;
    img {
      display: block;
      width: 151px;
      margin: 00 auto;
    }
  }
  &__subtitle {
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 24px;
    @include mobile {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 8px;
      line-height: 28px;
      span {
        font-size: 16px;
        line-height: 28px;
      }
    }
    span {
      display: inline-block;
      @include mobile {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  &__toBuy {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 92px;
    display: flex;
    justify-content: center;
    gap: 20px;
    @include mobile {
      margin-bottom: 56px;
      margin-top: 20px;
      gap: 12px;
    }
    &__btn,
    &__all {
      display: inline-block;
      padding: 9px 32px;
      border-radius: 50px;
      border: 1px solid #fff;
      background: #214c73;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.32);
      color: #fff;
      text-decoration: none;
      font-size: 22px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 1.1px;
      cursor: pointer;
      display: flex;
      align-items: center;
      @include mobile {
        padding: 6px 24px;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
      img {
        width: 28px;
        height: 28px;
        margin-right: .25em;
        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
    }
    .unlockList__toBuy__all.disabled {
      opacity: 0.7;
      pointer-events: none;
    }
    .unlockList__toBuy__btn {
      background-color: #fff;
      border: 1px solid #214c73;
      color: #214c73;
    }
  }
  // list 是一個每行 6 個的列表, 並且在手機版時每行 3 個
  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile {
      width: 100%;
      justify-content: space-around;
    }
    &__item {
      width: 25%;
      margin-bottom: 84px;
      display: flex;
      flex-direction: column;
      justify-content: centers;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 47.924px;
      letter-spacing: 1.6px;
      color: #fff;
      cursor: pointer;
      @include mobile {
        width: 33%;
        margin-bottom: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 1.12px;
      }
      &__pic {
        width: 220px;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-size: contain !important;
        @include mobile {
          width: 132px;
          height: 132px;
        }
        img {
          width: 108px;
          height: 104px;
          transform: translateY(30px);
          @include mobile {
            width: 64px;
            height: 61px;
            transform: translateY(20px);
          }
        }
        &.background-1 {
          background: url("../../assets/運勢書共用/外框1.png") center center
            no-repeat;
        }
        &.background-2 {
          background: url("../../assets/運勢書共用/外框2.png") center center
            no-repeat;
        }
        &.background-3 {
          background: url("../../assets/運勢書共用/外框3.png") center center
            no-repeat;
        }
      }
      &__title {
        margin-top: -48px;
        font-size: 20px;
        font-weight: 400;
        line-height: 47.924px;
        letter-spacing: 1.6px;
        display: flex;
        align-items: center;
        @include mobile {
          margin-top: -28px;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 1.12px;
        }
        img {
          width: 24px;
          margin-right: 8px;
          @include mobile {
            width: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
