var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FixedPopupVue", [
    _c("div", { staticClass: "bookSelectBuy" }, [
      _c("div", { staticClass: "bookSelectBuy__title" }, [
        _vm._v("選擇想購買/送禮的星座")
      ]),
      _c(
        "div",
        { staticClass: "bookSelectBuy__list" },
        [
          _c("div", { staticClass: "bookSelectBuy__list__item" }, [
            _c("div", { staticClass: "bookSelectBuy__list__item__pic" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/運勢書共用/2024全部購買符號.svg"),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "bookSelectBuy__list__item__title" }, [
              _vm._v("全部購買")
            ]),
            _c(
              "div",
              {
                staticClass: "bookSelectBuy__list__item__select",
                on: {
                  click: function($event) {
                    return _vm.toBuyAll()
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../assets/月亮日記/icon-ok.svg"),
                    alt: ""
                  }
                })
              ]
            )
          ]),
          _vm._l(_vm.productListShow, function(product) {
            return _c(
              "div",
              {
                key: product.ProductID,
                staticClass: "bookSelectBuy__list__item"
              },
              [
                _c("div", { staticClass: "bookSelectBuy__list__item__pic" }, [
                  product.img
                    ? _c("img", { attrs: { src: product.bookImg, alt: "" } })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "bookSelectBuy__list__item__title" }, [
                  _vm._v(_vm._s(product.cn) + "座")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "bookSelectBuy__list__item__select",
                    on: {
                      click: function($event) {
                        return _vm.selectHandler(product)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/月亮日記/icon-ok.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ]
            )
          })
        ],
        2
      ),
      _c("div", { staticClass: "bookSelectBuy__closeBtn" }, [
        _c("img", {
          attrs: { src: require("../../assets/月亮日記/close.svg"), alt: "" },
          on: {
            click: function($event) {
              return _vm.$emit("exit")
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }